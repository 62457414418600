:root {
  --dark-green: #406037;
  --mid-green: #609966;
  --light-green: #9dc08b;
  --text-color: #fee8b0;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.text {
  font-family: "Segoe UI";
  font-weight: 500;
  font-style: normal;
  font-size: 2.5rem;
}

.text-smaller {
  font-family: "Segoe UI";
  font-weight: 200;
  font-style: normal;
  font-size: 1.75rem;
  max-width: 600px;
  word-wrap: break-word;
}

.App {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.fire-simulation {
  position: absolute;
  width: 100%;
  height: 100%;
}

.simulation-overlay {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  /* have to do pixels here to account for the border */
  width: 100%;
  height: 100%;
}

.center {
  position: relative;
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: fixed;
  top: -10%;
  color: var(--text-color);
}

.me-image-container {
  width: 30%;
  height: 30%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 30%;
  margin-bottom: 40px;
  margin-top: 20%;
  box-shadow: 0px -5px 20px rgba(250, 156, 255, 0.5);
}

.me-image-container img {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 5px rgba(250, 156, 255, 0.2);
}

.about-me-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 60px;
  box-shadow: 2px 2px 40px rgba(25, 26, 17, 0.75);
  font-size: 1.5rem;
  font-family: "Iosevka Web";
  font-weight: 500;
  font-style: normal;
  color: var(--text-color);
}

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 5%;
}

.info-box p {
  margin: 10px 10px;
}

.social-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.social-icon {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
  filter: invert(1);
}

.social-icon-github {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.social-icon:hover,
.social-icon-github:hover {
  transform: scale(1.2);
  cursor: pointer;
}

@media (max-width: 850px) {
  .text {
    font-size: 6vw;
  }

  .text-smaller {
    font-size: 4vw;
  }

  .me-image-container {
    width: 40%;
    margin-top: 25%;
  }
}
